const contracts = {
  presale: {
    1337: "0x568510b843d4F950d0F772e62C7903320F7cA53B",
    56: "0xa3D28f8477dEB481875aF83f3E034e6411Bc3a35",
    97: "0xb08992B4b6A4b3De2b5320Fa2e759C3624342D53",
  },
  presaleVault: {
    1337: "0x09401D568fe76A4f4522d495750B39D505315D27",
    56: "0x2f29b2F513aC991b12217A8224e4e5EB0736c59B",
    97: "0x129e0a1151774c02E39D1aAAb34401E1C30132d8",
  },
  lemaToken: {
    1337: "0x18B7bf638817c0195cfb98F7b22139807110CCa3",
    56: "0x55ec329Ae952970A04a9fC0fE8F4622D90E5A6C1",
    97: "0x92c211b2F5F14bD65eCD7d114168Ab14C063274C",
  },
  busd: {
    1337: "0x3df1515359D529C8cB2C81178D9CCb51901fAD96",
    56: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    97: "0xcf1aecc287027f797b99650b1e020ffa0fb0e248",
  },
};

export default contracts;
